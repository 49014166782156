/* Table look changes start */

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-bottom: 0;
}

body .p-paginator {
    border: 1px solid #e5e5e5;
    background-color: #fff !important;
}

body .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #e5e5e5;
}


/* Table look changes end */

body .pi {
    font-size: 1em;
}

.imgStyles {
    width: 25px !important;
    height: 25px !important;
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: #f95c00eb;
    color: #ffffff;
}
body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #64677778;
    color: #ffffff;
}
body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background-color: #64677778;
    color: #ffffff;
}