// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.sidebar__link {
    width: 100%;
}

.sidebar__submenu .sidebar__link {
    padding-left: 20px;
}

.topBarImageAlignment {
    text-align: center;
}

.changemodal {
    width: 330px;
}

.themeColorText {
    color: $color-accent;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 5px;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;

    background-color: #000 !important;
}

.topbarMenu .topbar__link .topbar__link-title {
    color: #fff !important;
}

.topbarMenu .topbar__link .topbar__link-icon {
    color: #fff !important;
    font-weight: bold;
    line-height: 20px;
}

.theme-white {
    border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.25rem;
    border: 1px solid gray;
    &::placeholder{
        color: rgb(160, 160, 160);
    }
}

.form__form-group-field label{
    margin-bottom: 0.25rem;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

.p-datatable table {
    min-width: 576px !important;
}

//auto complete mwnu list is moving up
.p-autocomplete-panel {
    // position: unset !important;
    margin-top: 26px
}

.p-autocomplete .p-autocomplete-panel {
    top: 10% !important;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 350px;
}

.redBackground {
    border-width: 1px;
    border-color: #ff0000;
    // border-radius: 10%;
    padding: 20px;
    background-color: #fff !important;
    width: '50%';
    border-radius: 8px;
}

.ApraisalWrngText {
    color: white;
    font-size: 14px
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: #f17038;
    color: black;
}


.colorLegend {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.p-datatable-emptymessage {
    text-align: center;
}

.sidebar__submenu-wrap {
    padding-left: 20px;
}

//autocomplete height
.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    height: 33px;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

.form-control {
    width: '90%' !important
}

.form-control {
    width: '90%' !important
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: #f17038 !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #ffffff !important;
    background-color: #f17038 !important;
    color: #ffffff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    margin-top: 10px !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0 0.857em !important;
}

.birthDayBackground {
    border-width: 2px;
    border-color: #05f3e7;
    // border-radius: 10%;
    padding: 20px;
    background-color: #ffffff !important;
    width: '50%';
    border-radius: 8px;
}

.fontbold {
    font-weight: bold;
}

.employeeCommentColor {
    color: blue;

}

.checkbox-btn__checkbox-custom {
    border: 1px solid #757677;
}

.detailsImgStyle {
    height: 120px;
}

.clientDetailsPhoto{
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.clientDetailsPageProfileContainer{
    @media screen and (max-width:576px){
        text-align: center;
    }
    @media screen and (min-width:576px) and (max-width:768px){
        text-align: left;
    }
    @media screen and (min-width:768px){
        text-align: right;
    }
}

.clientPropsScroll{
    height: 600px;
    overflow-x: hidden;
}

body .p-multiselect .p-multiselect-label {
    height: 25px;
    margin: 0em;
}

.propertyCard{
    border: none;
    border-radius: 3px;
    background-color: #fff;
    // box-shadow: 2px 2px 4px 2px #d6d7da;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    min-height: 200px;
}
.propertyCard:hover {
    transform: scale(1.1);
}


.photoViewModal.modal-show.modal-dialog {
    width: 100% !important;
    height: 90% !important;
}
.photoViewModal .modal-content{
    padding: 0px !important;
    background: transparent;
    box-shadow: none;
}
.photoViewModal .awssld__content{
    background: transparent;
}
.photoViewModal .awssld__controls__arrow-left:before, 
.photoViewModal .awssld__controls__arrow-left:after, 
.photoViewModal .awssld__controls__arrow-right:before, 
.photoViewModal .awssld__controls__arrow-right:after{
    background-color: #000;
}


.photoViewModalSliderDiv{
    display: contents;
}
.photoViewModalSliderDiv img{
    width: 80% !important;
}
.photoViewModalImg {
    height: '-webkit-fill-available' !important;
    width: '-webkit-fill-available' !important;
}
.photosModalCloseIcon{
    // position: absolute;
    // z-index: 3;
    // width: 100%;
    // text-align: end;
    // cursor: pointer;
    // right: 55px;
    display: flex;
    justify-content: flex-end;
    margin: 10px
}
.closePhotosIcon{
    fill: white;
}



// Image hover css in table row
.img__wrap {
    height: auto;
    width: auto;
  }
  
  .img__description {
    margin-top: 0px;
    position: absolute;
    top: 90px;
    left: 50%;
    background: rgba(78, 80, 82, 0.514);
    color: #fff !important;
    visibility: hidden;
    opacity: 0;
  
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }
  
  .img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
  }

  .cursorPointer{
      cursor: pointer;
  }
  .bgWhite{
      background-color: #ffffff !important;
  }

  .loginTranslateButton{
    width: auto !important;
    margin-left: auto;
  }
.formErrorText{
    color: #ad4444;
    font-size: 10px;
    line-height: 13px;
}

.filtersContainer{
    border: solid 1px rgb(209, 209, 209);
    border-radius: 10px;
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 0px;
    background-color: #ffffff;
}

// .propertiesContainer {
//     border: solid 1px rgb(209, 209, 209);
//     height: calc(100vh - 200px);   
// }
@media screen and (max-width: 575px) {
    .hideInMobile{
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .showInMobile{
        display: none;
    }
}

@media screen and (min-width: 570px) and (max-width: 770px) {
    .mobileView_topbar {
        display: none !important;
    }
}

.dashboard_cards {
    padding-top: 15px;
}

.topbar_wrapper_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.topbar_arrow {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.topbar_arrow svg {
    margin-top: 19px;
}

.large-icon {
    width: 45px;
    height: 45px;
    color: white;
}

.mobileView_topbar {
    flex: 1;
    margin-right: 25px;
}

.mobileView_topbar span {
    color: white;
    font-weight: 600;
    font-size: 22px;
    margin-top: 21px;
}

.topbar__right {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.activeTopBar {
    color: $color-white;
    font-weight: 900;
    // background: $color-accent;
    &:before {
      opacity: 1;
    }
}

.emptyPropsContainer {
 height: calc(100vh - 220px);    
}

.themeLink{
    color: black;
    &:hover {
        color: white
    }
}

.paginatorStyles.p-paginator{
  background: none;
}

.bgWhite{
    background-color: #ffffff;
}

.p-fileupload-files img {
    height: 150px;
    width: 200px;
}  

.addPropertyScreen{
    // height: calc(100vh - 60px);
    padding-top: 10px;
    background-color: #ffffff;
}

.addPropertyScrollbar {
    height: calc(100vh - 80px );
    // overflow-x: hidden;
}

.myPropertiesListScroll{
    height: calc(100vh - 212px);
    width: 100%;
}

.myPropertiesSearchScroll{
    height: calc(100vh - 280px);
    width: 100%;   
}

.addPropertySearchArea{
    height: 85px;
    width: 95%;
    border: 1px solid #ced4da;
    color: #646777;
    border-radius: 10px;
    &:focus {
        // ff7b00
        border: 1px solid #ff7b00;
    }
    &::placeholder{
        color: rgb(160, 160, 160);
    }
}

.textAreaPh {
    &::placeholder{
        color: rgb(160, 160, 160) !important;
    }
}

.propertyCardCss {
    margin: 0px 0px 20px 0px;
    padding: 0px 0px;
    border : solid 1px #d1d1d1;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    min-height: 355px;

    &:hover {
        box-shadow: 0px 0px 2px 1px $color-accent;
        border: none;
    }
    overflow: hidden;
}

.propertyCardBodyCss{
    border-radius: 10px;
    height: 90px;
}

.propertyCardImage{
    width: 100% ;
    height: 180px;
    object-fit: cover;
}

.propertyCardFooter{
    background-color: #ffffff;
    border: none;
}

.gridCard{
    margin: 0px 0px 20px 0px;
    padding: 0px 0px;
    border : solid 1px #d1d1d1;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    height: auto;
    border: none;
    &:hover {
        box-shadow: 0px 0px 2px 1px $color-accent;
        border: none;
    }
    overflow: hidden;
}

.gridCardBodyCss{
    border-radius: 10px;
}

.gridField{
    font-size: 15px;
    display: flex;
    justify-content: start;
    margin-bottom: 10px;
    margin: auto;
    color: #212529 !important;
    .gridFieldKey{
        text-align: left;
        font-weight: bold;
        text-transform: capitalize;
        width: fit-content;
        white-space: nowrap;
        padding-right:  0px !important;
    }
    .gridFieldValue{
        text-align: left;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.gridActions{
    position: absolute;
    top: 10px;
    right: 10px;
}

.custom-button{
    .custom-button-icon{
        margin: auto !important;
    }
    &:disabled{
        .custom-button-icon{
            color: #212529 !important;
        }
    }
}

.logo-wrapper{
    width: 25px;
    img{
        border-radius: 50%;
    }
}

.dropdown-menu {
    min-width: 9rem !important;
    left: -120px;
}
.dropdown-item{
    padding-left: 0px !important;
}

.borderRight {
    border-right: 3px solid rgba(190, 190, 190, 0.514);
}

.shadowCss {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

.addPropIcon{
        color: $color-accent;
    &:hover{
        color:darken($color: $color-accent, $amount: 10%);
    }
}

body .p-fileupload-choose:not(.p-disabled) {
    background-color: $color-accent;
    border-color: $color-accent;
    &:hover{
        background-color: darken($color: $color-accent, $amount: 10%);
        border-color: darken($color: $color-accent, $amount: 10%);
    }
    &:active{
        background-color: darken($color: $color-accent, $amount: 10%);
        border-color: darken($color: $color-accent, $amount: 10%);
    }
}

// .textUpperCase{
//     text-transform: uppercase;
// }

.textCapitalize{
    text-transform: capitalize;
}

// Hide Arrows for input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-link.p-highlight{
    background-color: $color-accent;
    border-radius: 50%;
    &:focus{
        // box-shadow: 0 0 0 0.2em darken($color:$color-accent, $amount: 0);
        box-shadow: none;
    }
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-link{
    border-radius: 50%;
    &:focus{
        // box-shadow: 0 0 0 0.2em darken($color:$color-accent, $amount: 0);
        box-shadow: none;
    }
}
body .p-paginator .p-paginator-first, 
body .p-paginator .p-paginator-prev, 
body .p-paginator .p-paginator-next, 
body .p-paginator .p-paginator-last {
    border-radius: 50%;
    &:focus{
        box-shadow: none;
    }
}

.clientsTableImgStyles {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
}

.propertyCheckBox{
    position: absolute;
    left: 2px;
    top: 2px;
}

.propertyCheckBox .checkbox-btn__checkbox-custom{
    background-color: white;
}

.propertyPriceOverlay{
    position: absolute;
    z-index: 1;
    right: 0px;
    padding: 3px 5px;
    color:$color-white;
    font-weight: 600;
    font-size: 14px;
}
.propertyPriceOverlay.rent{
    top: 130px;
    background-color:$color-themeBlue;
}
.propertyPriceOverlay.sale{
    top: 152px;
    background-color:$color-themeBlue;
}

.cornerRibbon{
    width: 100px;
    background: $color-accent;
    position: absolute;
    top: 18px;
    left: auto;
    right: -22px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 1px;
    color: #f0f0f0;
    font-weight: 800;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.mark_images {
    width: 240px;
    background: red;
    position: absolute;
    top: 38px;
    right: auto;
    left: -55px;
    text-align: center;
    line-height: 25px;
    letter-spacing: 1px;
    color: #f0f0f0;
    font-weight: 1000;
    font-size: 16px;
    transform: rotate(45deg);
    -webkit-transform: rotate(-40deg);
}


.howLongText {
    color: rgb(99, 99, 99);
}

.howLongIcon {
    height: 17px;
    width: 17px;
    vertical-align: sub;
}
.shareIconAlignment {
    margin: 0px;
    width: 16px;
    height: 16px;
    fill:$color-themeBlue;
    &:hover{
        fill:  red;
    }
}
.shareIcons { 
    margin: 0px;
    width: 20px;
    height: 20px;
}
.w-45{
    width: 45px;
}
.viewMoreLink{
    vertical-align: text-bottom;
    // position: absolute;
    z-index: 1;
    color: rgb(99, 99, 99);
    fill: rgb(99, 99, 99);
    &:hover{
        color: $color-accent;
        fill: $color-accent;
    }
}

.viewMoreIconAlignment{
    width: 18px;
    height: 18px;
    vertical-align: sub;
}

.secondaryText{
    color: $color-gray;
}

.formModalBorderRadius .modal-content{
    border-radius: 10px;
}
.formModalBorderRadius .modal-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.formPhotoField{
    position: absolute;
    right: 0px;
    color: #000000;
    cursor: pointer;
}

//Select/Dropdown Field Custom Css
.css-1szy77t-control{
    border-color: $color-accent !important;
    box-shadow: 0 0 0 0px $color-accent !important;
    border-radius: 10px !important;

    &:focus{
        border-color: $color-accent !important;
        box-shadow: 0 0 0 0px $color-accent !important;
    }

    &:hover{
        border-color: $color-accent !important;
        box-shadow: 0 0 0 0px $color-accent !important;
    }
}

.css-bg1rzq-control{
    border-radius: 10px !important;
}

.addPropBackLink{
    background-color: $color-accent;
    color: #ffffff;
    font-weight: 600;
    &:hover{
        color: #ffffff;
    }
}

.blackText {
    color: #000 !important;
}

.propertDetailsPadding{
   padding-bottom: 12px;
}

.clientCriteriaCard{
    border: 0px solid $color-accent !important;
    box-shadow: 0px 0px 1px 1px $color-accent !important;
    z-index: 1;
    &:hover {
        box-shadow: 0px 0px 2px 2px $color-accent !important;
    }
}

.passwordInfoIcon{
    fill: $color-accent;
}
.viewMatchedEyeIcon {
    vertical-align: bottom;
    z-index: 1;
    color: rgb(190, 190, 190);
    fill: rgb(190, 190, 190);
    &:hover{
        color: $color-accent;
        fill: $color-accent;
        cursor: pointer;
    }
}
.invalidFilterModal .modal-content{
    padding: 10px 20px !important;
}

.propertyForRibbon{
    position: relative;
    background: rgb(255, 0, 0);
    color: white;
    font-weight: 600;
    transform: rotate(45deg);
    text-align: center;
    top: 11px;
    left: 234px;        /* !!! */
    width:80px;        /* !!! */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;
}

.contactInfoIcon{
    height: 15px;
    width: 15px;
    margin-left: 4px;
    fill: $color-themeBlue;
    vertical-align: sub;
}
.contactInfoToggle1{
    color: $color-themeBlue;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
}
.contactInfoToggle2{
    color: $color-accent;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
}

.showContactText{
    height: 40px;
}

.backIcon {
    fill: $color-accent;
    height: 38px;
    width: 38px;
    margin-top: 1px;
    margin-bottom: 1px;
    &:hover{
        fill: darken($color: $color-accent, $amount: 5%);
        height: 40px;
        width: 40px;
        margin-top: 0px;
    margin-bottom: 0px;
    }
}
.propertyDetailsShareIcon{
    fill: $color-accent;
    height: 27px;
    width: 27px;
}

.criteriaUpdateCss{
    &:hover{
        background-color: $color-accent !important;
        border-color: $color-accent !important;
    }
    &::before{
        background-color: $color-accent !important;
        border-color: $color-accent !important;
    }
}

.propDetailsPhotoSlider .awssld__content{
    background-color:transparent;
}

.matchedPropsButtonRow{
    @media screen and (max-width:576px) {
        margin-left:13px !important;
        margin-right:13px !important;
    }
}

.globalShareIcon_icon{
    background-color: #000;
   height: 18px;
   width: 18px;
   border-radius: 50%;
   margin-right: 6px;
   color: #ffffff;
    padding-right: 2px;
    vertical-align: sub;
    margin-left: 1px;
}

.globalShareIcon_label{
    color: #646777;
}

.globalShareIcon {
    &:hover{
        .globalShareIcon_icon{
            background-color: $color-accent;
        }
        .globalShareIcon_label{
            color: $color-accent;
        }
        cursor: pointer;
    }
}

.clientFormPhotoField{
    position: absolute;
    left: 0px;
    cursor: pointer;
    z-index: 1;
    color: red;
}

.description_alingment{
    // margin-top: 10px;
    align-self: center;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and ( min-width:575px  ) and ( max-width:768px){
       height: 190px;
    }
}
.clientImage{
    height: 200px;
    // object-fit: cover;
    @media screen and (min-width:768px) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    @media screen and (max-width:768px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
     }
}
.clientMyProps{
    justify-content: center;
    border: 1px solid $color-accent;
    border-radius:10px;
    &:hover {
        box-shadow: 0px 0px 4px 1px $color-accent;
    }
}

.propertiesTitleToShareDiv{
    border: 1px solid $color-accent;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}

.linkOverCardCss {
    color: $color-themeBlack;
    &:hover {
        color: $color-themeBlack;
    }
}

.dashBoardCountCardCss{
    &:hover {
        box-shadow: 0px 0px 2px 0.5px $color-accent;
    }
    color: $color-accent;
    font-size: 19px;
}

.awssld__controls__arrow-right{
    height:30px !important;
}
.awssld__controls__arrow-left {
    height:30px !important;
}

// PrimeReact FileUpload
body .p-button{
    background-color: $color-accent !important;
    border-color: $color-accent !important;
}

body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus{
    box-shadow: none;
}

body .fileUploadComponent .p-button:nth-child(2){
    display: none;
}

.editPropRemovePicIcon{
    position: absolute;
    right: 5px;
    fill: $color-accent;
    cursor: pointer;
    z-index: 10;
}

.profileEditImage {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin: 0px;
}

.buttonTextWeight {
    font-weight: bold;
}

.myPropDeleteIcon {
  align-self: center;
}

.myPropEditIcon{
    vertical-align: text-bottom;
}

body .fileUploadComponent .p-fileupload-content{
    max-height: 340px;
    overflow-y: auto !important;
    @media screen and (max-width:768px){
        max-width: 87vw ;
    }
    @media screen and (min-width:768px){
        max-width: 44vw ;
    }
}

body .fileUploadComponent .p-fileupload-content .p-fileupload-files .p-fileupload-row>div:nth-child(2){
  display: none;
}
body .fileUploadComponent .p-fileupload-content .p-fileupload-files .p-fileupload-row>div:nth-child(3){
    display: none;
}

.registrationForm{
    max-width: 500px;
}

.pageHeadingCss {
    text-transform: uppercase;
    font-size: 18px;    
    font-weight: 400;
    white-space: nowrap;
}

.pageSubHeadingCss {
    text-transform: uppercase;
    font-size: 17px;    
}

.propertyCardHeading {
    font-size: 16px;
}

.dashboardCountIcon {
    width: 35px;
    height: 35px;
    color: $color-accent;
}

.dashboardCountTitle{
    padding: 20px 0px;
    font-size: 16px;
    color: $color-themeBlack;
    text-transform: uppercase;
}

.dashboardCountNumber{
    font-size: 22px;
    font-weight: 400;
}

.propertyCardDetails{
    font-size: 13px !important;
}

.detailsFont {
    font-size: 14px !important;
}

.clientCriteriaCardHeading {
    font-size: 17px !important;
}

.lightText {
    color: #4d4d4d;
}

.propTitleToShare{
    background-color: #e2e2e2;
    margin: 4px;
}
.css-1hwfws3{
    height: 32px !important;
}

.data {
    // position: relative;
    display: inline-block;
  }
  
   .dataText {
    // visibility: hidden;
    // width: 120px;
    // background-color: black;
    // color: #fff;
    // text-align: center;
    // border-radius: 6px;
    // padding: 5px 0;
  
    // /* Position the tooltip */
    // position: absolute;
    // z-index: 1;
    visibility: hidden;
    background-color: #ff7b00;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-top: 10px;
    white-space: break-spaces;
    text-align: left;
  }
  
  .data:hover .dataText {
    visibility: visible;
  }

  .p-datatable-scrollable-body{
    position: unset !important;
  } 

.addPropDropZone.dropzone.dropzone--multiple{
    min-height: 200px;
    height: 220px !important;
    overflow-y: auto;
}
.addPropDropZone .dropzone__input{
    height: 200px;
    min-height: 200px !important;
    outline: none;
}
.addPropDropZone .dropzone__img-delete{
    right: 4px;
}

.react-select__input input{
    font-size: 100%;
    height: 14px;
    margin-bottom: 0px;
}
.react-select__value-container{
    position: unset !important;
}
.react-select .css-1g6gooi{
    margin: 0px 0px 3px 0px;
}
.css-151xaom-placeholder{
    margin: 0px !important;
}
.css-dvua67-singleValue{
    margin: 0px !important;
}
.clientSearch .react-select__clear-indicator {
    padding: 0px 0.5rem !important;
}
.clientSearch .react-select__input input{
    font-size: 100% !important;
    height: 14px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.svg-icons svg {
    margin-right: 0px !important;
}

.form_modal .modal-content{
    text-align: justify !important;
}

.checkbox_mobile span{
    width: 21px !important;
    height: 21px !important;
    margin-left: 2px;
    margin-top: 2px;
}

.add_prop_btn{
    height: 32px !important;
}

.form_modal .modal__header{
    background-color: white !important;
    border-color: white;
}

.form_modal .modal__header button{
  color: black;
  top: 16px;
}

.form-container {
    position: relative;
    overflow: hidden;
}

.form-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    padding: 10px;
}

.form-content {
    width: 100%;
    padding-top: 10px;
    max-height: 50vh;
    overflow-y: auto;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.button_reNew button {
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    padding: 3px 10px !important;
}

.button_reNew .btn.btn-secondary {
    background-color: $color-accent;
    color: white;
}

.dashboard_card{
    padding: 12px;
    font-size: large;
    font-weight: 500;
    display: flex;
    justify-content: center;
}